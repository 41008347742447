import React, { useState } from 'react';
import BuyMeACoffeeButton from './BuyMeCoffee';
import MiniCalculator from './MiniCalculator';
import LoanCalculator from './LoanCalculator';
import VisitorCount from './VisitorCount';
import { Tabs, Tab } from '@mui/material';
import InvestmentCalculator from './InvestmentCalculator';
import FinancialFreedomCalculator from './FinancialFreedomCalculator';
import { DarkMode, LightMode } from '@mui/icons-material';

function Home() {
  const [value, setValue] = useState(0);
  const [theme, setTheme] = useState('light');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <section className={`min-h-screen flex flex-col items-center ${theme === 'light' ? 'bg-gray-100 text-black' : 'bg-gray-900 text-white'}`} style={{ background: theme === 'light' ? 'linear-gradient(135deg, #8d8556, #3c3a32)' : 'linear-gradient(135deg, #1a1a1a, #3c3a32)', position: 'relative' }}>
      {/* Tema Seçici - Sağ Üst Köşe */}
      <button 
        onClick={toggleTheme} 
        className="absolute top-4 right-4 flex items-center p-2 rounded"
        style={{ zIndex: 999 }} // Z-index değerini artırdık
      >
        {theme === 'light' ? <DarkMode /> : <LightMode />}
        {/* Ekran boyutuna göre metni göster */}
        {window.innerWidth > 600 && (
          <span className="ml-2">{theme === 'light' ? 'Karanlık Tema' : 'Aydınlık Tema'}</span>
        )}
      </button>

      {/* Sekmeler */}
      <div className="w-full max-w-4xl">
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          orientation={window.innerWidth <= 600 ? 'vertical' : 'horizontal'}
          sx={{
            backgroundColor: '#ddd',
            color: 'black',
            borderRadius: '8px',
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          }}
        >
          <Tab label="Döviz Çeviri" />
          <Tab label="Kredi Hesaplayıcı" />
          <Tab label="Yatırım Getirisi Hesaplayıcı" />
          <Tab label="Finansal Özgürlük Hesapla" />
        </Tabs>
      </div>

      {/* İçerik Alanı */}
      
        {value === 0 && <MiniCalculator />}
        {value === 1 && <LoanCalculator />}
        {value === 2 && <InvestmentCalculator />}
        {value === 3 && <FinancialFreedomCalculator />}
     

      {/* Sabit Alt Alanlar */}
      <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2">
  <BuyMeACoffeeButton />
</div>

      <footer className="mt-8 w-full bg-gray-800 text-white py-4 flex justify-center items-center">
        <div className="text-center">
          <VisitorCount />
        </div>
      </footer>
    </section>
  );
}

export default Home;
